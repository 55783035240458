export type AntaaAccountSubTypeString = 'pharmaceutical' | 'publisher';

const PHARMACEUTICAL = 'pharmaceutical';
const PUBLISHER = 'publisher';

export const AntaaAccountSubType: {
  PHARMACEUTICAL: AntaaAccountSubTypeString;
  PUBLISHER: AntaaAccountSubTypeString;
} = {
  PHARMACEUTICAL,
  PUBLISHER,
};
