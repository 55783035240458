import { getPublicRuntimeConfig } from '../config';
import { AntaaAccountType } from './antaaAccountType';
import { AntaaAccountSubType } from './antaaAccountSubType';
import { CommentDisplayMode } from './commentDisplayMode';
import { DiscloseScope } from './discloseScope';
import { isLicenseVerified } from './user';

export type ArticleType = 'SLIDE' | 'VIDEO';
export type ArticleStatus = 'CONVERTING' | 'DONE' | 'ERROR' | 'DELETED';

export type Article = {
  articleId: string;
  authorId: number;
  name: string | null;
  description: string | null;
  status: ArticleStatus | null;
  type: ArticleType;
  topImageM: string | null;
  topImageL: string | null;
  width: number | null;
  height: number | null;
  discloseScope: DiscloseScope;
  pageCount: number;
  sessionCount: number;
  likeCount: number; // スライド保存とページ保存の合算値
  createDate: string; // ISO8601
  updateDate: string; // ISO8601
  createDateFormatted: string; // YYYY/MM/DD
  updateDateFormatted: string; // YYYY/MM/DD
  updateDateEpochMilliseconds: number;
  pages: ArticlePage[];
  video: ArticleVideo | null;
  highlight: string | null;
  references: string[];
  hidden: boolean;
  needLogin: boolean;
  commentDisplayMode: CommentDisplayMode;
  tableOfContents: TableOfContents[] | null;
};

export type ArticlePage = {
  articleId: string;
  pageNumber: number;
  slideText: string | null;
  linkUrl: string | null;
  imgL: string;
};

export type ArticleVideo = {
  fileName: string;
};

export type User = {
  userId: number;
  userName: string | null;
  lastName: string | null;
  firstName: string | null;
  hasProfileImage: boolean;
  idBaseProfileUrl: string | null;
  updateDateEpochMilliseconds: number;
  discloseId: string | null;
  antaaAccountType?: string | null;
  antaaAccountSubType?: string | null;
  isOfficialAccount?: boolean | null;
};

export type Author = {
  userId: number;
  userName: string | null;
  lastName: string | null;
  firstName: string | null;
  updateDateEpochMilliseconds: number;
  profilePhotoS3Key: string | null;
  idBaseProfileUrl: string | null;
  antaaAccountType?: string | null;
  antaaAccountSubType?: string | null;
  isOfficialAccount?: boolean | null;
};

type AuthorExProp = {
  accountType?: string | null;
  accountSubType?: string | null;
};

export type TableOfContents = {
  pageRange: string;
  contents: string;
};

function getSlideWebBaseUrl() {
  const { slideWebBaseUrl } = getPublicRuntimeConfig();
  return slideWebBaseUrl;
}

export function createArticleRelativeUrl(articleId: string) {
  return `/article/view/${articleId}`;
}

export function createArticleUrl(articleId: string) {
  return `${getSlideWebBaseUrl()}${createArticleRelativeUrl(articleId)}`;
}

export function createArticlePageRelativeUrl(
  articleId: string,
  pageNum: number,
) {
  return `${createArticleRelativeUrl(articleId)}#${pageNum}`;
}

export function createArticlePageUrl(articleId: string, pageNum: number) {
  return `${createArticleUrl(articleId)}#${pageNum}`;
}

export function createArticleEditRelativeUrl(articleId: string) {
  return `/article/edit/${articleId}`;
}

export function createArticleEmbedUrl(articleId: string) {
  return `${getSlideWebBaseUrl()}/article/embed/${articleId}`;
}

export function createArticleImageRelativeUrl(
  articleId: string,
  imageName: string | null,
  options?: {
    version?: number;
  },
) {
  if (!imageName) {
    return null;
  }
  const name = imageName.startsWith('/') ? imageName.substring(1) : imageName;
  let url = `/api/article/view-image/${articleId}/${name}`;

  if (options?.version) {
    const p = new URLSearchParams();
    p.set('v', String(options.version));
    url = `${url}?${p.toString()}`;
  }

  return url;
}

export function createArticleImageUrl(
  articleId: string,
  imageName: string | null,
  options?: {
    version?: number;
  },
) {
  const relativeUrl = createArticleImageRelativeUrl(
    articleId,
    imageName,
    options,
  );
  if (!relativeUrl) {
    return null;
  }
  return `${getSlideWebBaseUrl()}${relativeUrl}`;
}

export function shouldNotShow(
  authorIdprofile: User & AuthorExProp,
  viewer: Express.User | undefined,
  isAuthenticated: boolean,
  discloseScope: DiscloseScope | null, // スライド以外の表示要否チェック時はnullを指定する
) {
  const authorAccountType =
    authorIdprofile?.accountType || authorIdprofile?.antaaAccountType;
  const authorAccountSubType =
    authorIdprofile?.accountSubType || authorIdprofile?.antaaAccountSubType;
  const isCorporationAuthor =
    authorAccountType === AntaaAccountType.CORPORATION;
  const isPharmaceuticalAuthor =
    authorAccountSubType === AntaaAccountSubType.PHARMACEUTICAL;
  const isPublisherAuthor =
    authorAccountSubType === AntaaAccountSubType.PUBLISHER;

  // 非ログイン時
  if (!isAuthenticated) {
    if (isCorporationAuthor && isPharmaceuticalAuthor) {
      return true;
    }
    return false;
  }

  const viewerDiscloseId = viewer?.discloseId;
  // 法人アカウントでログイン時
  if (
    isAuthenticated &&
    viewer?.antaaAccountType === AntaaAccountType.CORPORATION &&
    discloseScope
  ) {
    // 自身のスライドは閲覧可
    if (
      (authorIdprofile?.discloseId || authorIdprofile?.userId) ===
      viewerDiscloseId
    ) {
      return false;
    }

    // 自身以外の法人アカウントの投稿スライドは非表示
    if (isCorporationAuthor) {
      return true;
    }

    if (viewer?.antaaAccountSubType === AntaaAccountSubType.PHARMACEUTICAL) {
      // 製薬会社アカウントでログイン時
      // 公開設定＝全員以外のスライドは非表示とする
      if (discloseScope !== 'FULL') {
        return true;
      }
    }
  }

  // 投稿者が法人アカウントの場合
  if (isCorporationAuthor) {
    const isCorporationViewer =
      viewer?.antaaAccountType === AntaaAccountType.CORPORATION;

    const isOtherViewer =
      viewerDiscloseId != // 意図的に!==にはしていない。
      (authorIdprofile.discloseId || authorIdprofile.userId);

    if (isPublisherAuthor) {
      if (!viewerDiscloseId || (isCorporationViewer && isOtherViewer)) {
        return true;
      }
    } else if (isPharmaceuticalAuthor) {
      const isRestrictedViewer =
        !viewerDiscloseId ||
        viewer?.antaaAccountType === AntaaAccountType.STUDENT ||
        !isLicenseVerified(viewer || null);

      if (isRestrictedViewer || (isCorporationViewer && isOtherViewer)) {
        return true;
      }
    }
  }
  return false;
}
