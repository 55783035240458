import React, { useEffect } from 'react';
import {
  Badge,
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Text,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useAppContext } from '../contexts/app';
import useAnnounce, { Announce, AnnounceContext } from '../hooks/useAnnounce';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';
import getConfig from '../common/config';
import { isPharmaceuticalAccount } from '../common/libs/user';

function AnnounceList({ announces }: { announces: Announce[] }) {
  const {
    publicRuntimeConfig: { slideWebBaseUrl },
  } = getConfig();
  const isExternalUrl = (url?: string | null) => {
    return !!url && url.startsWith('http') && !url.startsWith(slideWebBaseUrl);
  };

  const getDatetimeWithoutYearAndSeconds = (createDate: string) => {
    const date = new Date(createDate);
    let format = 'MM/DD hh:mm';
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    return format;
  };

  return (
    <>
      {announces.map((announce) => (
        <>
          <Link
            color="black"
            href={announce.linkUrl || undefined}
            isExternal={isExternalUrl(announce.linkUrl)}
            onClick={() => {
              sendAnalyticsEvent('announce', 'click', announce.linkUrl || null);
            }}
          >
            <Text fontSize={['xs', 'sm']} color="gray">
              {getDatetimeWithoutYearAndSeconds(announce.createDate)}
            </Text>
            {!!announce.imgUrl && (
              <Image
                src={announce.imgUrl}
                w="full"
                h="full"
                maxH={'220px'}
                alt={announce.message}
                objectFit="contain"
                borderRadius={8}
              />
            )}
            <Flex justifyContent={'space-between'} minH={14}>
              <Center justifyContent={'flex-start'}>
                <Text fontSize={['sm', 'md']}>{announce.message}</Text>
                {!!announce.linkUrl && isExternalUrl(announce.linkUrl) && (
                  <ExternalLinkIcon mx={2} />
                )}
                {!!announce.linkUrl && !isExternalUrl(announce.linkUrl) && (
                  <Text w={3} mx={2}>
                    {'>'}
                  </Text>
                )}
              </Center>
              {announce.isNew && (
                <Center justifyContent={'flex-end'}>
                  <Badge colorScheme="red" ml={1}>
                    New
                  </Badge>
                </Center>
              )}
            </Flex>
            <Divider borderColor="darkgray" my={2} />
          </Link>
        </>
      ))}
      {announces.length === 0 && (
        <>
          <Center>（お知らせはありません）</Center>
        </>
      )}
    </>
  );
}

function NewAnnounceBadge({ announces }: { announces: AnnounceContext }) {
  return (
    <>
      {announces.hasUnread && announces.announces.length > 0 && (
        <Box
          w={2}
          h={2}
          mt={-3}
          borderRadius={'full'}
          bgColor={'red'}
          color="red"
        />
      )}
    </>
  );
}

export default function AnnounceModal() {
  const { isAuthenticated, user, announceModalDisclosure } = useAppContext();
  const {
    announces,
    fetchAnnounces,
    readAnnounces,
    userAnnounces,
    readUserAnnounces,
  } = useAnnounce();

  // モーダル開閉時の処理
  useEffect(() => {
    if (announceModalDisclosure.isOpen) {
      (async () => {
        await fetchAnnounces();
        readUserAnnounces();
      })();
    }
  }, [
    announceModalDisclosure.isOpen,
    isAuthenticated,
    fetchAnnounces,
    readUserAnnounces,
  ]);

  const onTabChange = (index: number) => {
    if (index === 1) {
      readAnnounces();
    }
  };

  const onClose = () => {
    fetchAnnounces(); // お知らせアイコンの新着バッジを更新するため
    announceModalDisclosure.onClose();
  };

  return (
    <Modal
      size={'lg'}
      onClose={onClose}
      isOpen={announceModalDisclosure.isOpen}
    >
      <Box pos="relative">
        <ModalOverlay />
        <ModalContent pos={'absolute'} right={[null, null, 10]}>
          <ModalHeader>お知らせ</ModalHeader>
          <ModalCloseButton />
          <Divider borderColor="gray.900" />
          <ModalBody mb={4} pl={[2, 4]} pr={[2, 4]} minH={'300px'}>
            <Tabs colorScheme={'green'} onChange={onTabChange}>
              <TabList>
                {isAuthenticated && !isPharmaceuticalAccount(user) && (
                  <Tab fontSize={['sm', 'md']}>
                    {`あなたへのお知らせ (${userAnnounces.announces.length})`}
                    <NewAnnounceBadge announces={userAnnounces} />
                  </Tab>
                )}
                <Tab fontSize={['sm', 'md']}>
                  {`Antaaからのお知らせ (${announces.announces.length})`}
                  <NewAnnounceBadge announces={announces} />
                </Tab>
              </TabList>
              <TabPanels>
                {isAuthenticated && (
                  <TabPanel>
                    <AnnounceList announces={userAnnounces.announces} />
                  </TabPanel>
                )}
                <TabPanel>
                  <AnnounceList announces={announces.announces} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Box>
    </Modal>
  );
}
